@import url(https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&family=Open+Sans&family=Roboto&display=swap);
body {
  margin: 0;
  padding: 0;

  font-family: "Roboto", sans-serif;
  background-color: #34495e !important;
}

h1 {
  font-family: "Lato", sans-serif;
}

.container {
  background: #fff;
  margin: 80px auto;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

@media print {
  * {
    -webkit-print-color-adjust: exact;
  }

  body {
    margin: 0;
    padding: 0;

    background-color: transparent !important;

    background-color: initial !important;
  }
}

.loading-spinner-container {
  position: fixed;
  left: 50%;
  top: 35%;
  z-index: 1052;
}

.loading-background {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;

  z-index: 1051;
  background-color: #000;
  opacity: 0.3;
}

.spinner-bg {
  position: relative;
  left: -50%;
}

nav.navbar-dark {
  background: #22313f;
}

nav.navbar .navbar-brand {
  padding: 0 !important;
}

nav.navbar .navbar-brand .navbar-brand-logo {
  height: 30px;
}



button.dropdown-toggle {
  height: 30px;
}


.dropdown-item.active, .dropdown-item:active {
  background: #22313f !important;
}






.home-container {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.home-container img.logo {
  max-width: 500px;
}


.search-container {
  width: 100%;
}

.search-container .home-search {
  border-radius: 20px;
  padding: 20px 40px;
  font-size: 1.3em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.search-container .home-search:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.search-container .home-search:focus {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  outline: none;
  border: 1px solid #ced4da;
}

.search-container .input-box {
  position: relative;
}

.search-container .input-box .search-icon {
  position: absolute;
  left: 15px;
  top: 14px;
}

.search-container .options-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.cursor-pointer {
  cursor: pointer;
}


.table-container-responsive {
  overflow-x: auto;
}

.table-small {
  overflow-y: auto;
  max-height: 200px;
}

table.table-fixed {
  table-layout: fixed;
}

@media screen and (max-width: 600px) {
  #card table {
    border: 0;
  }

  #card table thead {
    display: none;
  }

  #card table tr {
    margin-bottom: 20px;
    display: block;
    border-bottom: 2px solid #ddd;
    box-shadow: 2px 2px 1px #dadada;
  }

  #card table td {
    padding: 10px;
    display: block;
    text-align: right;
    font-size: 13px;
  }

  #card table td:last-child {
    border-bottom: 0;
  }

  #card table td::before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.upper {
  text-transform: uppercase;
}


.text-start {
  text-align: start !important;
}




.login-container .box-login {
  margin: 0 auto;
  margin-top: 150px;
  margin-bottom: 10px;
  max-width: 500px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  position: relative;
}

.login-container .box-login img.login-img {
  height: 250px;
  position: absolute;
  top: -125px;
  left: calc(50% - 116px);
  border: 3px solid #ccc;
}

.login-container .box-login form {
  margin-top: 100px;
}

.fake-background {
  position: absolute;
  top: 61.5px;
  left: 0;
  width: 100%;
  background: #34495e;
}

.usuario-container .form-container {
  max-width: 700px;
  margin: 0px auto;
}

.align-end {
  text-align: end;
}

.flex-2 {
  flex: 2 1
}

.image-selector-item {
  max-height: 500px;
  margin-right: 10px;
  overflow: hidden;
}

.image-selector-item img {
  max-height: 500px;
  cursor: pointer;
}

.image-selector-item.selected img {
  border: 3px solid #2574a9;
}

.image-selector-item:not(.selected) img {
  opacity: 0.5;
}

.image-selector-container {
  width: 100%;
  height: auto;

  display: flex;
  justify-content: center;
}





.password-container .form-container {
  max-width: 600px;
  margin: 30px auto;
}
.password-container .email-box {
  max-width: 600px;
  margin: 0 auto;
}


.ativo-container .hidden-row {
  padding: 0 !important;
}

.ativo-container .clickable-row {
  cursor: pointer;
}

.search-container {
  max-width: 800px;
  margin: 0 auto;
}

.ativo-container .form-container {
  max-width: 700px;
  margin: 0px auto;
}

.align-end {
  text-align: end;
}





.veiculo-container .form-container {
  max-width: 700px;
  margin: 0px auto;
}

.align-end {
  text-align: end;
}



.search-container {
  max-width: 800px;
  margin: 0 auto;
}


.card {
  max-width: 540px;
}


.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card-container.container-row {
  flex-direction: row;
}

.card-container.container-column {
  flex-direction: column;
}




.search-container {
  max-width: 800px;
  margin: 0 auto;
}






.formula-container img.img-list {
  height: 100px;
}

.formula-container span.formula-title {
  font-weight: 700;
  font-size: 1.3em;
  margin-left: 5px;
}

.formula-container .hidden-row {
  padding: 0 !important;
}

.formula-container .clickable-row {
  cursor: pointer;
}

.formula-container .form-container {
  max-width: 700px;
  margin: 0px auto;
}

.formula-container .img-view {
  width: 100%;
}

.align-end {
  text-align: end;
}


.formula-container .img-view {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}






img.img-list {
  height: 100px;
}

.medicamento-container span.medicamento-title {
  font-weight: 700;
  font-size: 1.3em;
  margin-left: 5px;
}

.search-container {
  max-width: 800px;
  margin: 0 auto;
}

.medicamento-container .form-container {
  max-width: 700px;
  margin: 0px auto;
}

.medicamento-container .img-view {
  width: 100%;
}

.align-end {
  text-align: end;
}

.medicamento-container .img-view {
  width: 100%;
}






.card .card-title-btn-container {
  min-width: 75px;
}

.card .card-title-btn-container {
  min-width: 75px;
}
.card .card-title-btn-container {
  min-width: 75px;
}

.h125px {
  height: 125px !important;
}

.btn-secondary:not(:disabled):not(.disabled).unactive {
  background-color: #dddddd;
  border-color: #dddddd;
}


.h250px {
  height: 250px !important;
}

















.formula-container img.img-list {
  height: 100px;
}

.formula-container span.formula-title {
  font-weight: 700;
  font-size: 1.3em;
  margin-left: 5px;
}



img.img-list {
  height: 100px;
}

.medicamento-container span.medicamento-title {
  font-weight: 700;
  font-size: 1.3em;
  margin-left: 5px;
}












.w600px {
  width: 600px;
}

textarea.receita-field {
  resize: none;
}

.h100px {
  height: 100px !important;
}

.min-h650px {
  min-height: 650px;
}

.w600px {
  width: 600px;
}

.h650px {
  height: 650px !important;
}

.receita-field-container {
  margin: 0 auto;
}

textarea.receita-field {
  resize: none;
}



.unidade-container .form-container {
  max-width: 700px;
  margin: 0px auto;
}

.align-end {
  text-align: end;
}






.formula-container .img-view {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}










